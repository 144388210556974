<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-tabs>
        <v-tab @click="type = 'room'">Номера</v-tab>
        <v-tab @click="type = 'koika'">Койки</v-tab>
      </v-tabs>
      <v-tabs class="mb-3">
        <v-tab @click="loadRow(false)">Активные</v-tab>
        <v-tab @click="loadRow(true)">Не активные</v-tab>
      </v-tabs>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :single-select="true"
        :single-select-value="'room'"
        item-key="room"
        show-select
        :loading="loading"
        :server-items-length="roomLength"
        :items-per-page="page_size"
        @update:options="updatePagination"
        :footer-props="{ 'items-per-page-options': items_per_page }"
      >
        <template v-slot:[`item.status_text`]="{ item }">
          <div class="d-flex justify-start">
            <span class="success">{{ item.status }}</span>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small v-if="!deleted" class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="!deleted"
            small
            class="mr-2"
            color="red darken-2"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon
            v-if="deleted"
            small
            class="mr-2"
            color="success"
            @click="deleteItem(item)"
          >
            mdi-power
          </v-icon>
        </template>
        <template v-slot:bottom> </template>
      </v-data-table>
      <v-btn @click="createRoom" color="primary" class="ma-3">
        Добавить номер
      </v-btn>
    </v-card>
    <v-dialog v-model="dialog" width="400">
      <v-card style="padding: 30px 0px;">
        <h3 style="text-align: center" v-if="!deleted">
          Вы уверены что хотите удалить номер? <br />
          В ней могут находиться брони
        </h3>
        <h3 style="text-align: center" v-else>
          Вы хотите восстановить номер?
        </h3>
        <div
          class="mt-4"
          style="display:flex; align-items:center;justify-content:center;"
        >
          <v-btn @click="deleteRoom" :loading="deleteLoading" color="primary"
            >{{ !deleted ? 'Удалить' : 'Восстановить' }}</v-btn
          >
          <v-btn @click="dialog = false">Отмена</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import user from "../../../store/auth";
import api from "gantt-schedule-timeline-calendar";
import toast from "../../../plugins/toast";
import { CategoriesApiUrls } from "@/services/accommodationRequests/categories.api.js"


export default {
  name: "Rooms",
  data() {
    return {
      deleteLoading: false,
      tmpItem: {},
      dialog: false,
      singleSelect: false,
      selected: [],
      type: 'room',
      headers: [
        {
          text: "Название",
          value: "name",
          class: "table-title",
          sortable: true
        },
        {
          text: "Категория",
          value: "category_name",
          class: "table-title",
          sortable: true
        },
        { text: "Инфо", value: "info", class: "table-title", sortable: true },
        {
          text: "Статус",
          value: "status",
          class: "table-title",
          sortable: true
        },
        {
          text: "Действие",
          value: "actions",
          class: "table-title",
          sortable: false
        }
      ],
      items: [],
      deleted: false, 
      items_per_page: [10, 20, 50],
      page_size: 10,
      page: 1,
      editedIndex: -1,
      editedItem: {
        id: null,
        name: null,
        category: {
          name: null
        },
        category_id: null,
        info: null,
        action: null
      },
      defaultItem: {
        id: null,
        name: "",
        room: null,
        category: {
          name: null
        },
        category_id: null,
        info: null,
        action: null
      },
      loading: false,
      roomLength: 0,
      options: {}
    };
  },
  watch: {
    type(){
      this.loadRow()
    }
  },
  created() {
    this.loadRow();
  },
  methods: {
    async deleteRoom() {
      this.deleteLoading = true;
      try{
        await CategoriesApiUrls.deleteRoom(this.tmpItem.id)
        toast.success("Успешно удалено!")
        this.items = [];
        this.loadRow();
        const state = api.api.stateFromConfig(this.$store.state.config);
        state.update("config.list.rows", null);
        this.dialog = false;
        this.deleteLoading = false;
      }
      catch(e){
        this.dialog = false;
        this.deleteLoading = false;
      }
    },
    updatePagination(pag) {
      this.page = pag.page;
      this.page_size = pag.itemsPerPage;
      this.loadRow();
    },
    async loadRow(value = null) {
      this.loading = true;
      if(value !== null)
        this.deleted = value;
      let params = {
        "page[number]": this.page, 
        "page[size]": this.page_size,
        room_type: this.type, 
        deleted: this.deleted
      }
      let response = await CategoriesApiUrls.getRooms(params)
      this.options = response.meta.pagination;
      this.roomLength = this.options.count;
      this.initialize(response.results);
    },
    initialize(response) {
      this.items = [];
      response.map(el => {
        const defaultRoom = {
          id: el.id,
          name: el.name,
          room: el.id,
          category_id: el.category_id,
          category_name: el.category_name,
          status: el.status,
          info: el.info
        };
        this.items.push(defaultRoom);
      });
      this.loading = false;
    },
    editItem(item) {
      this.$router.push("/room/" + item.id);
    },
    deleteItem(item) {
      this.dialog = true;
      this.tmpItem = item;
    },
    createRoom() {
      this.$router.push("/room");
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
